function trackVideo(player) {
    const isVimeo = player.element?.src?.includes('vimeo.com') || player.element?.hasAttribute('data-vimeo-video-id');

    const videoPlayPercentages = {
        play50: false,
        play75: false,
        play100: false
    };
    window.dataLayer = window.dataLayer || [];
    if(isVimeo) {
        player.on('timeupdate', function (data) {
            if (data.percent >= 0.5 && !videoPlayPercentages.play50) {
                player.getVideoTitle().then(function (title) {
                    videoPlayPercentages.play50 = true;
                    window.dataLayer.push({
                        'event': 'siteengagement.click',
                        'siteengagementType': 'video plays (50%+)',
                        'siteengagementName': '50% - ' + title 
                    });
                });
            }
            if (data.percent >= 0.75 && !videoPlayPercentages.play75) {
                player.getVideoTitle().then(function (title) {
                    videoPlayPercentages.play75 = true;
                    window.dataLayer.push({
                        'event': 'siteengagement.click',
                        'siteengagementType': 'video plays (50%+)',
                        'siteengagementName': '75% - ' + title 
                    });
                });
            }
            if (data.percent === 1) {
                player.getVideoTitle().then(function (title) {
                    window.dataLayer.push({
                        'event': 'siteengagement.click',
                        'siteengagementType': 'video plays (50%+)',
                        'siteengagementName': '100% - ' + title 
                    });
                    videoPlayPercentages.play50 = false;
                    videoPlayPercentages.play75 = false;
                });
            }
        });
    }
}

export { trackVideo };