import $ from 'jquery';

// private Fast Selector wrapper,
// returns jQuery object. Only use where
// getElementById is not available.
var S = function (selector, context) {
    if (typeof selector === 'string') {
        if (context) {
            var cont;
            if (context.jquery) {
                cont = context[0];
                if (!cont) {
                    return context;
                }
            } else {
                cont = context;
            }
            return $(cont.querySelectorAll(selector));
        }

        return $(document.querySelectorAll(selector));
    }

    return $(selector, context);
};

// Namespace functions.

var attr_name = function (init) {
    var arr = [];
    if (!init) {
        arr.push('data');
    }
    if (this.namespace.length > 0) {
        arr.push(this.namespace);
    }
    arr.push(this.name);

    return arr.join('-');
};

var add_namespace = function (str) {
    var parts = str.split('-'),
        i = parts.length,
        arr = [];

    while (i--) {
        if (i !== 0) {
            arr.push(parts[i]);
        } else {
            if (this.namespace.length > 0) {
                arr.push(this.namespace, parts[i]);
            } else {
                arr.push(parts[i]);
            }
        }
    }

    return arr.reverse().join('-');
};

// Event binding and data-options updating.

var bindings = function (method, options) {
    var self = this,
        bind = function () {
            var $this = S(this),
                should_bind_events = !$this.data(self.attr_name(true) + '-init');
            $this.data(
                self.attr_name(true) + '-init',
                $.extend({}, self.settings, options || method, self.data_options($this))
            );

            if (should_bind_events) {
                self.events(this);
            }
        };

    if (S(this.scope).is('[' + this.attr_name() + ']')) {
        bind.call(this.scope);
    } else {
        S('[' + this.attr_name() + ']', this.scope).each(bind);
    }
    // # Patch to fix #5043 to move this *after* the if/else clause in order for Backbone and similar frameworks to have improved control over event binding and data-options updating.
    if (typeof method === 'string') {
        return this[method].call(this, options);
    }
};

var single_image_loaded = function (image, callback) {
    function loaded() {
        callback(image[0]);
    }

    function bindLoad() {
        this.one('load', loaded);

        if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
            var src = this.attr('src'),
                param = src.match(/\?/) ? '&' : '?';

            param += 'random=' + new Date().getTime();
            this.attr('src', src + param);
        }
    }

    if (!image.attr('src')) {
        loaded();
        return;
    }

    if (image[0].complete || image[0].readyState === 4) {
        loaded();
    } else {
        bindLoad.call(image);
    }
};

window.Foundation = {
    name: 'Foundation',

    version: '5.5.2',

    stylesheet: $('<style></style>').appendTo('head')[0].sheet,

    global: {
        namespace: undefined,
    },

    init: function (scope, libraries, method, options, response) {
        var args = [scope, method, options, response],
            responses = [];

        // check RTL
        this.rtl = /rtl/i.test(S('html').attr('dir'));

        // set foundation global scope
        this.scope = scope || this.scope;

        this.set_namespace();

        if (libraries && typeof libraries === 'string' && !/reflow/i.test(libraries)) {
            if (this.libs.hasOwnProperty(libraries)) {
                responses.push(this.init_lib(libraries, args));
            }
        } else {
            for (var lib in this.libs) {
                responses.push(this.init_lib(lib, libraries));
            }
        }

        S(window).on('load', function () {
            S(window)
                .trigger('resize.fndtn.clearing')
                .trigger('resize.fndtn.dropdown')
                .trigger('resize.fndtn.equalizer')
                .trigger('resize.fndtn.interchange')
                .trigger('resize.fndtn.joyride')
                .trigger('resize.fndtn.magellan')
                .trigger('resize.fndtn.topbar')
                .trigger('resize.fndtn.slider');
        });

        return scope;
    },

    init_lib: function (lib, args) {
        if (this.libs.hasOwnProperty(lib)) {
            this.patch(this.libs[lib]);

            if (args && args.hasOwnProperty(lib)) {
                if (typeof this.libs[lib].settings !== 'undefined') {
                    $.extend(true, this.libs[lib].settings, args[lib]);
                } else if (typeof this.libs[lib].defaults !== 'undefined') {
                    $.extend(true, this.libs[lib].defaults, args[lib]);
                }
                return this.libs[lib].init.apply(this.libs[lib], [this.scope, args[lib]]);
            }

            args = args instanceof Array ? args : new Array(args);
            return this.libs[lib].init.apply(this.libs[lib], args);
        }

        return function () {};
    },

    patch: function (lib) {
        lib.scope = this.scope;
        lib.namespace = this.global.namespace;
        lib.rtl = this.rtl;
        lib['data_options'] = this.utils.data_options;
        lib['attr_name'] = attr_name;
        lib['add_namespace'] = add_namespace;
        lib['bindings'] = bindings;
        lib['S'] = this.utils.S;
    },

    inherit: function (scope, methods) {
        var methods_arr = methods.split(' '),
            i = methods_arr.length;

        while (i--) {
            if (this.utils.hasOwnProperty(methods_arr[i])) {
                scope[methods_arr[i]] = this.utils[methods_arr[i]];
            }
        }
    },

    set_namespace: function () {
        var namespace =
            this.global.namespace === undefined
                ? $('.foundation-data-attribute-namespace').css('font-family')
                : this.global.namespace;

        this.global.namespace =
            namespace === undefined || /false/i.test(namespace) ? '' : namespace;
    },

    libs: {},

    utils: {
        S: S,

        throttle: function (func, delay) {
            var timer = null;

            return function () {
                var context = this,
                    args = arguments;

                if (timer == null) {
                    timer = setTimeout(function () {
                        func.apply(context, args);
                        timer = null;
                    }, delay);
                }
            };
        },

        debounce: function (func, delay, immediate) {
            var timeout, result;
            return function () {
                var context = this,
                    args = arguments;
                var later = function () {
                    timeout = null;
                    if (!immediate) {
                        result = func.apply(context, args);
                    }
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, delay);
                if (callNow) {
                    result = func.apply(context, args);
                }
                return result;
            };
        },

        data_options: function (el, data_attr_name) {
            data_attr_name = data_attr_name || 'options';
            var opts = {},
                ii,
                p,
                opts_arr,
                data_options = function (el) {
                    var namespace = Foundation.global.namespace;

                    if (namespace.length > 0) {
                        return el.data(namespace + '-' + data_attr_name);
                    }

                    return el.data(data_attr_name);
                };

            var cached_options = data_options(el);

            if (typeof cached_options === 'object') {
                return cached_options;
            }

            opts_arr = (cached_options || ':').split(';');
            ii = opts_arr.length;

            function isNumber(o) {
                return !isNaN(o - 0) && o !== null && o !== '' && o !== false && o !== true;
            }

            function trim(str) {
                if (typeof str === 'string') {
                    return $.trim(str);
                }
                return str;
            }

            while (ii--) {
                p = opts_arr[ii].split(':');
                p = [p[0], p.slice(1).join(':')];

                if (/true/i.test(p[1])) {
                    p[1] = true;
                }
                if (/false/i.test(p[1])) {
                    p[1] = false;
                }
                if (isNumber(p[1])) {
                    if (p[1].indexOf('.') === -1) {
                        p[1] = parseInt(p[1], 10);
                    } else {
                        p[1] = parseFloat(p[1]);
                    }
                }

                if (p.length === 2 && p[0].length > 0) {
                    opts[trim(p[0])] = trim(p[1]);
                }
            }

            return opts;
        },

        image_loaded: function (images, callback) {
            var self = this,
                unloaded = images.length;

            function pictures_has_height(images) {
                var pictures_number = images.length;

                for (var i = pictures_number - 1; i >= 0; i--) {
                    if (images.attr('height') === undefined) {
                        return false;
                    }
                }

                return true;
            }

            if (unloaded === 0 || pictures_has_height(images)) {
                callback(images);
            }

            images.each(function () {
                single_image_loaded(self.S(this), function () {
                    unloaded -= 1;
                    if (unloaded === 0) {
                        callback(images);
                    }
                });
            });
        },

        match: function (mq) {
            return window.matchMedia(mq).matches;
        },
    },
};

$.fn.foundation = function () {
    var args = Array.prototype.slice.call(arguments, 0);

    return this.each(function () {
        Foundation.init.apply(Foundation, [this].concat(args));
        return this;
    });
};
